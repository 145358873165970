import React, { useState } from "react"
import chunk from "lodash/chunk"
import { graphql } from "gatsby"
import PostItem from "../components/PostItem"
import Layout from "../components/layout"
import "styled-components/macro"
import Helmet from "react-helmet"
import SEO from "../components/Seo"
import useEvent from "../hooks/useEvent"
import { SocialIcon } from "react-social-icons"
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";

const BioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`

const IconsWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
`

const Index = ({ location, data }) => {
  const [postsToShow, setPostsToShow] = useState(10)
  const [ticking, setTicking] = useState(false)

  const posts = data.allMdx.edges

  const update = () => {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight)
    if (distanceToBottom < 100) {
      setPostsToShow(postsToShow + 10)
    }
    setTicking(false)
  }

  const handleScroll = () => {
    if (!ticking || posts.length > postsToShow) {
      setTicking(true)
      requestAnimationFrame(() => update())
    }
  }

  useEvent(`scroll`, handleScroll)

  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1024px)" })

  return (
    <Layout postCount={posts.length} location={location}>
      <SEO title="Home" />
      <Helmet>
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:image" content={data.site.siteMetadata.siteUrl + "/og-image/site-image.jpg"} />
        <meta property="og:image:width" content="975" />
        <meta property="og:image:height" content="391" />
      </Helmet>
      {/* posts */}
      {chunk(posts.slice(0, postsToShow), 3).map((chunk, i) => (
        <div key={`chunk-${i}`}>
          {chunk.map(post => {
            const { slug } = post.node.fields
            const {
              title,
              cover,
              description,
              tags,
              excerpt,
            } = post.node.frontmatter
            const blogImgSrc = cover.childImageSharp.fixed

            return (
              <PostItem
                key={title + description}
                slug={slug}
                tags={tags}
                description={description}
                title={title}
                excerpt={excerpt}
                imageSrc={blogImgSrc}
              />
            )
          })}
        </div>
      ))}
      {isMobileOrTablet ? (
        <BioWrapper>
          <IconsWrapper>
            <SocialIcon
              url="https://www.youtube.com/channel/UC91waLGpNuo9Q0SjvtP9RVA"
              style={{ height: 32, width: 32 }}
              bgColor={"var(--logo)"}
            />
            <SocialIcon
              style={{ height: 32, width: 32 }}
              url="https://twitter.com/willmakestv"
              bgColor={"var(--logo)"}
            />
            <SocialIcon
              style={{ height: 32, width: 32 }}
              url="https://www.twitch.tv/willmakestv"
              bgColor={"var(--logo)"}
            />
            <SocialIcon
              style={{ height: 32, width: 32 }}
              url="https://github.com/WillMakesTV"
              bgColor={"var(--logo)"}
            />
          </IconsWrapper>
        </BioWrapper>
      ) : ''}
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(posts)/.*.(mdx|md)$/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            description
            tags
            cover {
              childImageSharp {
                fixed(width: 435) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
