import React, { useContext } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import "styled-components/macro"

import { DisplaySizeContext } from "../layout"
import {useMediaQuery} from "react-responsive";
const ColumnWrapper = styled.div`
  min-height: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PostItem = ({ slug, title, imageSrc, description, excerpt, tags }) => {

  let BlogImage
  if (typeof imageSrc === "string") {
    BlogImage = (
      <img
        src={imageSrc}
        alt={"Blog Post Cover"}
        css={`
          z-index: -9999;
          position: relative;
          overflow: hidden;
          display: inline-block;
          margin-right: 30px;
          margin-bottom: 0px;
        `}
      />
    )
  } else {
    BlogImage = (
      <Image
        fixed={imageSrc}
        css={`
          z-index: -9999;
          margin-right: 30px;
        `}
      />
    )
  }

  const context = useContext(DisplaySizeContext)
  const isMobile = useMediaQuery({ query: "(max-width: 665px)" })

  if (isMobile) {
    return (

      <ColumnWrapper key={title}>

        <RowWrapper>
          <h2
            css={`
              margin-bottom: 10px;
              margin-top: 0px;
            `}
          >
            <Link to={slug}>{title}</Link>
          </h2>
          </RowWrapper>

          <RowWrapper>
            <Link to={slug}>{BlogImage}</Link>
          </RowWrapper>

          <RowWrapper>
            <h4
              css={`
                margin-top: 0px;
                margin-bottom: 0px;
                line-height: normal;
              `}
            >
              {description || excerpt}
            </h4>
          </RowWrapper>

      </ColumnWrapper>
  )
  } else {
      return (
        <RowWrapper key={title}>
          <Link to={slug}>{BlogImage}</Link>


          <ColumnWrapper>
            {context.isMobileOrTablet ? (
              <h3
                css={`
              margin-bottom: 10px;
              margin-top: 0px;
            `}
              >
                <Link to={slug}>{title}</Link>
              </h3>
            ) : (
              <h2
                css={`
              margin-top: 0px;
            `}
              >
                <Link to={slug}>{title}</Link>
              </h2>
            )}
            {context.isMobileOrTablet ? (
              <>
                <h4
                  css={`
                margin-top: 0px;
                margin-bottom: 0px;
                line-height: normal;
              `}
                >
                  {description || excerpt}
                </h4>
              </>
            ) : (
              <h4
                css={`
              margin-top: 0px;
              margin-bottom: 0px;
              line-height: normal;
            `}
              >
                {/*{tagLinks}*/}
                {description || excerpt}
              </h4>
            )}
          </ColumnWrapper>
        </RowWrapper>
      )
    }
  }

  export default PostItem
